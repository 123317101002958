import React from "react";
import { Manufacturer, manufacturers, MeterConfig, Model } from "../utils/config";
import { getImagePath, getManufacturerImage, getModelImage } from "../utils";

const ManufacturerSelector: React.FC<{ onSelect: (manufacturer: Manufacturer) => void }> = ({ onSelect }) => (
  <div className="flex flex-wrap gap-4">
    {manufacturers.map((manufacturer) => (
      <div className="ams-card-manu" key={manufacturer.id} onClick={() => onSelect(manufacturer)}>
        {manufacturer.img && (
          <div className="w-40">
            <img className="w-full" src={getImagePath()+manufacturer.img} alt={manufacturer.name} />
          </div>
        )}
        {manufacturer.img ? "" : manufacturer.name}
      </div>
    ))}
  </div>
);

const ModelSelector: React.FC<{ manufacturer: Manufacturer; onSelect: (model: Model) => void }> = ({
  manufacturer,
  onSelect,
}) => (
  <div className="mt-2 flex flex-wrap gap-2">
    {manufacturer.models.map((model) => (
      <div className="ams-card" key={model.id} onClick={() => onSelect(model)}>
        {model.img && (
          <div className="w-40">
            <img className="w-full" src={model.img} alt={model.name} />
          </div>
        )}
        {model.name}
      </div>
    ))}
  </div>
);

const ConfigSelector: React.FC<{ model: Model; onSelect: (config: MeterConfig) => void }> = ({ model, onSelect }) => (
  <div className="mt-2 flex flex-col gap-2">
    {model.configs.map((conf) => (
      <div className="ams-card" key={conf.option.id} onClick={() => onSelect(conf)}>
        {conf.option.name} ({conf.socket.name})
      </div>
    ))}
  </div>
);
export { ManufacturerSelector, ModelSelector, ConfigSelector };
