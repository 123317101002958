import React, { useState } from "react";
import { Manufacturer, MeterConfig, Model } from "../utils/config";
import { ConfigSelector, ManufacturerSelector, ModelSelector } from "./SelectorElements";
import { getImagePath } from "../utils";
export default function Selector() {
  const [manufacturer, setManufacturer] = useState<Manufacturer | null>(null);
  const [model, setModel] = useState<Model | null>(null);
  const [conf, setConf] = useState<MeterConfig | null>(null);
  const [zoom, setZoom] = useState(false);

  const setSelectedModel = (model: Model) => {
    if (model) {
      setModel(model);
      if (model.configs && model.configs.length === 1) setConf(model.configs[0]);
    }
  };

  return (
    <div className="container mx-auto h-full w-full p-3">
      <h3 className={"py-4 text-4xl font-bold"}>Selection Guide</h3>
      <div className="flex flex-col gap-2 rounded bg-white p-2 shadow">
        {!manufacturer && (
          <>
            <span className={"font-bold"}>Smart meter manufacturer:</span>
            <ManufacturerSelector onSelect={setManufacturer} />
          </>
        )}
        {manufacturer && !model && (
          <>
            <div className={"flex py-2"}>
              <button className={"ams-button"} onClick={() => setManufacturer(null)}>
                Back
              </button>
            </div>
            {manufacturer && manufacturer.model_img && (
              <div className={"flex items-center justify-center"}>
                <div className={zoom ? "w-full cursor-zoom-out" : "flex w-80 cursor-zoom-in"}>
                  <img
                    onClick={() => setZoom(!zoom)}
                    className="w-full"
                    src={getImagePath()+manufacturer.model_img}
                    alt={manufacturer.name}
                  />
                </div>
              </div>
            )}
            <div>
              Available Models for <span className={"font-bold"}>{manufacturer.name}</span>:
            </div>
            <ModelSelector manufacturer={manufacturer} onSelect={setSelectedModel} />
          </>
        )}
        {manufacturer && model && (
          <>
            <div className={"flex py-2"}>
              <button
                className={"ams-button"}
                onClick={() => {
                  setModel(null);
                  setConf(null);
                }}
              >
                Back
              </button>
            </div>
            <div>
              <span className={"font-bold"}>{manufacturer.name}</span>
            </div>
            <div>
              Available Smart meter options for <span className={"font-bold"}>{model.name}</span>:
            </div>
            <ConfigSelector model={model} onSelect={setConf} />
          </>
        )}
        {conf && (
          <div className="flex flex-col gap-2 py-2">
            <hr />
            <p>
              The selection you made is known to be in use in the following countries:{" "}
              <span className={"font-bold"}>{conf.countries.join(", ")}</span>.
            </p>
            <div className={"flex flex-col gap-2"}>
                <p className="py-2 text-2xl font-bold">{conf.advice.advice}</p>
              {conf.advice.link && (
                <a href={conf.advice.link} className={"ams-card w-fit"}>
                  {conf.advice.img && <img src={conf.advice.img} className={"w-60"} alt="item-img" />}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
