export const getManufacturerImage = (manufacturerId: string) => {
  switch (manufacturerId) {
    default:
      return "https://picsum.photos/400?random=" + manufacturerId;
  }
};
export const getModelImage = (modelId: string) => {
  switch (modelId) {
    default:
      return "https://picsum.photos/400";
  }
};

export const getImagePath=()=>{
  let d = document.getElementById("root")
  if (d)
    return d.dataset["img"]
  else return ""
}
